<template>
  <div class="showorder">
    <span class="tittle">{{$t('m.personalcenter_myorder.My_Orders')}}</span>
    <div class="bodystatus">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
        v-for="(i,k) in panList" :key="k"
        :label="i.label+`${labelStatus[k]!=0?` (${labelStatus[k]})`:''}`"
        :name="i.name"
        >
          <component :is="activeName"
          @olistData="()=>{orderListShow}">
          </component>
        </el-tab-pane>
      </el-tabs>
      <div class="div_orderlist">
        <order-list></order-list>
      </div>

      <!-- 订单搜索功能 -->
      <!-- <div id="sear">
        <input id="searin" type="text" placeholder="商品名称/订单号">
        <button class="sou">搜索</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import orderList from './orderList.vue'

import emitter from '../../tools/bus'
import { reactive, ref } from 'vue-demi'
import http from '../../api/http.js'

export default {
  name: 'myOrder',
  data () {
    return {
      panList: [
        { label: this.$t('m.personalcenter_myorder.All_Orders'), name: 'allorder' }, // 全部订单
        { label: this.$t('m.personalcenter_myorder.Pending_payments'), name: 'obligation' }, // 待付款
        // { label: this.$t('m.personalcenter_myorder.Wait_for_early_delivery'), name: 'withholding' }, // 待预提
        // { label: this.$t('m.personalcenter_myorder.Goods_to_be_confirmed'), name: 'confirmed' }, // 待确认备货
        { label: this.$t('m.personalcenter_myorder.wait_for_receiving'), name: 'pending' }, // 待收货
        { label: this.$t('m.personalcenter_myorder.finished'), name: 'completed' } // 已完成
      ], // 订单状态标题内容
      activeName: 'allorder', // 当前选择的订单项
      // 存储各个种类订单数量
      // 0全部订单 1待付款 2待预提 3待确认备货 4待发货 5已完成
      labelStatus: [0, 0, 0, 0, 0, 0],
      orderDatashow: reactive([]), // 当前状态的订单
      orderDataS: reactive(), // 初始化显示全部订单
      totalnum: ref(), // 存放总数据个数
      pagesize: ref(10), // 存放一页显示个数
      currentPage: ref(1) // 默认展示第几页
    }
  },
  components: {
    orderList
  },
  methods: {
    // 初始化数量
    initdata () {
      http.get('/mall-order/order/getOrderStatusByMemberId') // 查询各个状态的数量接口
        .then(res => {
          // console.log(res.data.data)
          for (const i in res.data.data) {
            // console.log(i)
            const orders = res.data.data[i].status
            // console.log(typeof orders)
            this.labelStatus[0] += res.data.data[i].quantity
            if (orders === 0 || orders === 12 || orders === 30 || orders === 31) {
              this.labelStatus[1] += res.data.data[i].quantity
            }
            if (orders === 30 || orders === 31 || orders === 32 || orders === 33 || orders === 34) {
              this.labelStatus[2] += res.data.data[i].quantity
            }
            if (orders === 3 || orders === 8 || orders === 15 || orders === 17 || orders === 34) {
              this.labelStatus[3] += res.data.data[i].quantity
            }
            if (orders === 6) {
              this.labelStatus[4] += res.data.data[i].quantity
            }
            if (orders === 9) {
              this.labelStatus[5] += res.data.data[i].quantity
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    // 点击切换的函数
    handleClick (tab, event) {
      console.log(tab.props)
      this.orderListShow(tab.props.name)
    },
    // 切换时查看的状态 调用
    orderListShow (data) {
      this.activeName = data
      switch (data) {
        case 'allorder':
          this.toShowList('', data)
          // emitter.emit('orderDataTo', { showNum: this.totalnum, name: this.activeName, list: this.orderDatashow })
          break
        case 'obligation': this.toShowList('12,0,30,31', data)
          break
        case 'withholding': this.toShowList('30,31,32,33,34', data)
          break
        case 'confirmed': this.toShowList('3,8,15,17,34', data)
          break
        case 'pending': this.toShowList('6', data)
          break
        case 'completed': this.toShowList('9', data)
          break
        default:
          break
      }
    },
    // 处理不同状态的切换展示效果
    toShowList (status, name) {
      this.orderDatashow = []
      const listdata = {
        status: status,
        page: this.currentPage,
        size: 10
      }
      const loadingpage = this.$loading({
        lock: true,
        text: '加载中...'
      })
      http.get('/mall-order/order/getOrderListByMemberIdAndStatus', listdata)
        .then(res => {
          console.log(res)
          // console.log(res.data.data)
          if (res.data.code === 200) {
            this.orderDatashow = res.data.data.records
            this.activeName = name
            res.data.data.records.forEach((i, k) => {
              i.omsOrderItem.productAttr = JSON.parse(i.omsOrderItem.productAttr) // 把规格 格式化显示
            // console.log(i)
            // i.presellTimeScope = i.presellTimeScope.split(',') // 处理时间
            })
            console.log(this.orderDatashow)
            emitter.emit('orderDataTo', { showNum: res.data.data.total, name: this.activeName, list: this.orderDatashow })
          }
          loadingpage.close()
        }).catch(err => {
          console.log(err)
          loadingpage.close()
        })
      // for (const i in data) {
      //   if (data[i].status === name - 1) {
      //     this.orderDatashow.push(data[i])
      //   }
      // }
    }
  },
  mounted () {
    this.initdata() // 初始化数量
    this.orderListShow('allorder') // 初始化查询所有订单
    // this.initdata()
    emitter.on('toOrderPageNum', (data) => {
      this.currentPage = data.val
      this.orderListShow(data.name)
    })
  },
  setup () {
    return {}
  }
}
</script>

<style lang="scss">
.showorder{
  padding: 0 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .tittle{
    height: 70px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 70px;
  }
  .bodystatus{
    position: relative;
    .el-tabs{
      .el-tabs__header{
        .el-tabs__nav-wrap{
          .el-tabs__nav-scroll{
            .el-tabs__nav{
              .el-tabs__active-bar{
                background: #C6824E;
              }
              .is-active{
                color: #C6824E;
              }
              .el-tabs__item:hover{
                color: #c49979;
              }
            }
          }
        }
      }
    }
    .div_orderlist{
      margin-top: 20px;
      width: 100%;
    }
    #sear{
      position: absolute;
      top: 0;
      right: 0;
      width: 320px;
      flex: 1;
      display: flex;
      align-items: center;
      #searin{
        // border: 1px solid #FFFFFF;
        box-sizing: border-box;
        background: 0;
        flex: 1;
        height: 30px;
        padding: 0 15px;
        outline: none;
        border: 1px solid;
        border-image: linear-gradient(0deg, #280E01, #673000) 10 10;
      }
      #searin::-webkit-input-placeholder {
        color: #999999;
      }
      #searin:-moz-placeholder {
        color: #999999;
      }
      #searin:-ms-input-placeholder {
        color: #999999;
      }
      #searin::-ms-input-placeholder{
        color: #999999;
      }
      .sou{
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        width: 60px;
        height: 30px;
      }
    }
  }
}
</style>
