<template>
  <div class="alltitle">
    <ul class="all_title_ul">
      <li class="all_title_li"
      v-for="i in alltitle" :key="i">
        {{i}}
      </li>
    </ul>
  </div>
  <div class="nodata" v-if="ListData.length == 0">
    <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E8%AE%A2%E5%8D%95%E4%B8%BA%E7%A9%BA.png" alt="">
  </div>
  <div class="order_div"
    v-for="(i,k) in ListData" :key="k">
    <div class="order_title">
      <p class="order_title_p">
        {{$t('m.personalcenter_myorder.order_number')}}：{{i.orderSn}}
      </p>
      <span class="order_title_span">
        {{i.createTime}}
        <!-- <button class="order_title_button">联系卖家</button> -->
      </span>
    </div>

    <div class="order_body">
      <ul class="order_body_ul">
        <li class="order_body_li body_num1">
          <div class="namebody" v-if="i.orderType!=11">
            <img :src="i.omsOrderItem.productPic" alt="">
            <div class="name_time">
              <p>{{i.omsOrderItem.productName}}</p>
              <span v-show="i.omsOrderItem.productAttr">
                {{$t('m.personalcenter_myorder.specification')}}:
                {{i.omsOrderItem.productAttr!=null?i.omsOrderItem.productAttr[1].value:null}}
                </span><br/>
              <span v-show="i.omsOrderItem.productAttr">
                {{$t('m.personalcenter_myorder.delivery_date')}}:
                {{i.omsOrderItem.productAttr!=null?i.omsOrderItem.productAttr[0].value:null}}</span>
            </div>
          </div>
          <!-- 易货订单 -->
          <div class="namebody" v-if="i.orderType==11">
            <img :src="i.exchangeProductPic" alt="">
            <div class="name_time">
              <p>{{i.exchangeProductName}}</p>
              <span>
                {{$t('m.personalcenter_myorder.Barter_date')}}:
                {{i.createTime}}</span>
            </div>
          </div>
        </li>
        <li class="order_body_li body_num2">
          <span class="li2_span_kg" v-if="i.orderType!=11">
            {{i.omsOrderItem.productQuantity-i.resaleNum}}{{i.pmsProduct.unit}}
          </span>
          <!-- 易货订单 -->
          <span class="li2_span_kg" v-if="i.orderType==11">
            {{i.sellQuantity}}{{i.demandUnit}}
          </span>
        </li>
        <li class="order_body_li body_num3">
          <p style="margin-bottom: 10px;">{{
            i.takeGoodsType==1?
            $t('m.personalcenter_myorder.logistics')
            : i.takeGoodsType==0?
            '自提'
            : i.orderType==11?
            '易货订单'
            :$t('m.personalcenter_myorder.None')}}</p>
          <div v-if="i.orderType!=11">
            <p class="name_phone_p">{{i.receiverName}}&emsp;{{i.receiverPhone}}</p>
            <p class="area_p">{{i.receiverDetailAddress}}</p>
          </div>
          <div v-if="i.orderType==11">
            <p class="name_phone_p">{{i.exchangeReceiverName }}&emsp;{{i.exchangeReceiverPhone}}</p>
            <p class="area_p">{{i.exchangeReceiverDetailAddress}}</p>
          </div>
        </li>
        <li class="order_body_li body_num4">
          <span class="money_span" v-if="i.orderType!=11">
            {{i.pmsProduct.currencySymbol || '￥'}}
            <!-- {{i.status==3 ? i.finalAmount : i.totalAmount}} -->
            {{i.payAmount}}
          </span>
          <!-- 易货订单 -->
          <span class="money_span" v-if="i.orderType==11">
            {{i.pmsProduct.currencySymbol || '￥'}}
            <!-- {{i.status==3 ? i.finalAmount : i.totalAmount}} -->
            {{i.exchangeProductCost}}
          </span>
        </li>
        <li class="order_body_li body_num5">
          <span class="status_name"> {{
                    i.status == 0
                      ? "待付款"
                      : i.status == 1
                      ? "已付款"
                      : i.status == 5
                      ? "待发货"
                      : i.status == 2
                      ? "卖家待备货"
                      : i.status == 3
                      ? "待确认备货"
                      : i.status == 12
                      ? "待付尾款"
                      : i.status == 8
                      ? "待确认发票"
                      : i.status == 4
                      ? "拒绝确认备货"
                      : i.status == 6
                      ? "待收货"
                      : i.status == 7
                      ? "已收货"
                      : i.status == 9
                      ? "已完成"
                      : i.status == 10
                      ? "已取消"
                      : i.status == 11
                      ? "订单失败"
                      : i.status == 15
                      ? "预订成功"
                      : i.status == 16
                      ? "预订失败"
                      : i.status == 17
                      ? "待确认"
                      : i.status == 30
                      ? "待付款-预提"
                      : i.status == 31
                      ? "待付款-待预提"
                      : i.status == 32
                      ? "已付款-待预提"
                      : i.status == 33
                      ? "卖家待确认预提"
                      : i.status == 34
                      ? "待确认预提"
                      : i.status == 35
                      ? "待重新预提"
                      : i.status == 40
                      ? "转售中"
                      : ""
                  }}</span>
          <button class="order_allmess"
          @click="orderDetailPage(i)"
          >{{$t('m.personalcenter_myorder.Order_details')}}</button>
        </li>
        <li class="order_body_li body_num6">
          <button class="order_paymoney" @click="orderBtn1(i,k)"
          v-if="orderName(i).btn1">{{orderName(i).btn1}}</button>
          <button class="order_remove"  @click="orderBtn2(i,k)"
          v-if="orderName(i).btn2">{{orderName(i).btn2}}</button>
        </li>
      </ul>
    </div>
    <div class="order_foot" v-if="i.orderType==10">
      <p class="order_foot_p">
        <!-- <el-tag type="warning">样品订单</el-tag> -->
        样品订单
      </p>
    </div>

    <div class="order_foot" v-if="i.status==30||i.status==31||i.status==32||i.status==33||i.status==34||i.status==35?true:false">
      <p class="order_foot_p">
        {{i.presellTimeScope.split(',')[0]}}&emsp;
        {{i.presellTimeScope.split(',')[1]}}
      </p>
    </div>

  </div>
  <!-- 待收货弹窗 -->
    <div v-if="showDialog == 'waitReceiving'" class="dialog_div">
      <el-dialog v-model="waitReceivingDialog" width="30%"
      :close-on-click-modal="false"
        destroy-on-close
        title="确认收货">
        <div class="namebody">
          <img :src="waitReceivingData.omsOrderItem.productPic" alt="">
          <div class="name_time">
            <p>{{waitReceivingData.omsOrderItem.productName}}</p>
            <div class="yh_div_show" v-if="orderType==11">
              <p>请支付物流补偿金：{{waitReceivingData.freightCompensation}}</p>
              <p>请支付装卸补偿金：{{waitReceivingData.carryMoney}}</p>
              <p>合计：{{waitReceivingData.carryMoney+waitReceivingData.freightCompensation}}</p>
            </div>
          </div>
          <el-input
            size="mini"
            placeholder="请输入支付密码"
            v-model="password"
            type="password"
            maxlength="6"
            minlength="6">
            </el-input>
          <el-button :loading="loadingbtn"
          @click="confirmReceiptBtn">确认收货</el-button>
        </div>
      </el-dialog>
    </div>

  <!-- 发票弹窗 -->
    <div v-if="showDialog == 'ConfirmInvoice'" class="dialog_div">
      <el-dialog v-model="ConfirmInvoiceDialog" width="30%"
      :close-on-click-modal="false"
        destroy-on-close
        title="确认发票">
        <div class="namebody">
          <el-input
            size="mini"
            placeholder="请输入支付密码"
            v-model="password"
            type="password"
            maxlength="6"
            minlength="6">
            </el-input>
          <el-button :loading="loadingbtn"
          @click="ConfirmInvoiceBtn">确认发票</el-button>
        </div>
      </el-dialog>
    </div>

  <!-- 转售弹窗 -->
    <div v-if="showDialog == 'resellBought'" class="dialog_div">
      <el-dialog v-model="resellBoughtDialog"
      @close="closeDialog(formData)"
      :close-on-click-modal="false" width="30%"
        destroy-on-close
        title="一键转售详情">
        <div class="zs_dialogbody">
          <div class="resell_div">
            <p class="pcont">商品名称：<span>{{ resellBoughtData.pmsProduct.name }}</span></p>
            <p class="pcont">商品单价：<span>
              {{resellBoughtData.pmsProduct.currencySymbol}}{{resellBoughtData.omsOrderItem.productPrice}}
              </span></p>
            <p class="pcont">商品规格：<span>
              {{resellBoughtData.omsOrderItem.productAttr[1].value}}
              </span></p>
            <p class="pcont">交收站：<span>
              {{resellBoughtData.pmsSiteEntity.name}}
              </span></p>
            <p class="pcont">可转数量：<span>
              {{resellBoughtData.omsOrderItem.productQuantity - resellBoughtData.resaleNum}}{{resellBoughtData.pmsProduct.unit}}
              </span></p>
          </div>
          <el-divider></el-divider>
          <div class="resell_div">
            <el-form :model="formData" label-width="100px"
            :rules="resellRules"
            ref="formData">
              <el-form-item label="转售数量：" prop="mount">
                <el-input-number
                v-model="formData.mount"
                size="mini"
                :step="resellBoughtData.pmsProduct.minIncr"
                :min="
                  resellBoughtData.omsOrderItem.productQuantity - resellBoughtData.resaleNum < resellBoughtData.pmsProduct.minBuy
                  ? resellBoughtData.omsOrderItem.productQuantity - resellBoughtData.resaleNum : resellBoughtData.pmsProduct.minBuy"
                :max="resellBoughtData.omsOrderItem.productQuantity - resellBoughtData.resaleNum"
                step-strictly
                @change="handleChange(formData.mount)"/>
              </el-form-item>
              <el-form-item label="转售单价：" prop="price">
                <el-input v-model="formData.price" maxlength="8"
                size="mini"></el-input>
              </el-form-item>
              <el-form-item label="结束时间：" prop="time">
                <el-date-picker
                v-model="formData.time"
                editable
                :disabledDate="disabledDate"
                value-format="YYYY-MM-DD"
                size="mini" type="date" placeholder="">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <el-button :loading="loadingbtn"
            @click="resellBoughtBtn">发布</el-button>
        </div>
      </el-dialog>
    </div>

  <!-- 待确认备货 -->
    <div v-if="showDialog == 'enterBeihuo'" class="dialog_div">
      <el-dialog
        title="确认备货信息"
        v-model="showBh"
        width="50%"
      >
        <div>
          <el-table :data="bhData" style="width: 100%">
            <el-table-column prop="orderSn" label="商品型号" width="200"/>
            <el-table-column prop="stockUpAmount" label="备货数量" />
            <el-table-column prop="freightAmount" label="物流费" />
            <!-- <el-table-column prop="jiagong" label="备货差价" /> -->
            <el-table-column prop="all" :label="bhfinalAmount>=0?'应付金额':'应退金额'" />
          </el-table>
          <div style="padding: 10px 0">
            <el-button
              @click="subConfirmGoods"
              >提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

  <!-- 底部分页 -->
  <div class="showfooter" v-if="totalnum<pagesize?false:true">
    <el-pagination
      v-model:currentPage="currentPage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalnum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
// import { getCurrentInstance } from 'vue'
import { getCurrentInstance, reactive, ref } from 'vue-demi'
import emitter from '../../tools/bus'
import { getStore, setStore } from '../../tools/storage'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import http from '../../api/http'

// 易货摘牌人确认收货
// const exchangeOrderConfirmDelivery = (data) => {
//   return http.post('/mall-order/order/exchangeOrderConfirmDelivery', data)
// }
// 取消订单
const cancelOrder = (data) => {
  return http.post('/mall-order/order/cancelOrder', data)
}
export default {

  name: 'orderList',
  // props: ['orderDataTo'],
  data () {
    return {
      alltitle: [
        this.$t('m.personalcenter_myorder.commodity_information'),
        this.$t('m.personalcenter_myorder.quantity'),
        this.$t('m.personalcenter_myorder.consignee'),
        this.$t('m.personalcenter_myorder.amount'),
        this.$t('m.personalcenter_myorder.state'),
        this.$t('m.personalcenter_myorder.operate')
      ],
      listName: '', // 当前状态名
      ListData: reactive({}),
      totalnum: ref(6), // 存放总数据个数
      pagesize: ref(10), // 存放一页显示个数
      currentPage: ref(1), // 默认展示第几页

      showDialog: ref(''), // 要展示的弹窗
      password: '', // 支付密码
      dataIndex: '', // 当前选中某条的数据

      orderType: '', // 确认是什么类型的订单
      presellTimeScope: '', // 预提开始时间
      // 确认收货
      waitReceivingDialog: ref(false), // 确认收货弹窗
      waitReceivingData: '', // 确认收货展示的数据
      loadingbtn: false, // 收货按钮
      // 确认备货
      showBh: false, // 确认备货弹窗
      bhData: '', // 备货信息
      bhfinalAmount: 0, // 应付金额/应退金额
      bhSn: '', // 备货订单Sn
      // 确认发票
      ConfirmInvoiceDialog: ref(false), // 确认发票弹窗
      ConfirmInvoiceData: '', // 发票存储数据
      InvoiceData: '', // 发票信息
      // 转售
      resellBoughtDialog: ref(false), // 确认发票弹窗
      resellBoughtData: '', // 转售存储数据
      resellTime: '', // 转售时间限制
      formData: reactive({
        price: '',
        mount: 0,
        time: ''
      }), // 转售表单数据
      resellRules: reactive({
        mount: [{
          required: true,
          message: '请输入转售数量',
          trigger: 'blur'
        }
        ],
        price: [
          {
            required: true,
            message: '请输入正确的转售价格',
            trigger: 'blur'
          },
          {
            pattern: /^[+-]?(0|([1-9]\d*))(\.\d{1})?$/,
            message: '最多小数点后一位'
          }
        ],
        time: [
          {
            required: true,
            message: '请选择结束时间',
            trigger: 'blur'
          }
        ]
      })
    }
  },
  methods: {
    orderName (item) {
      // console.log(item)
      const orders = item.status
      const msgBtn = (orders) => {
        if (orders === 0 || orders === 12 || orders === 30 || orders === 31) {
          return { btn1: this.$t('m.personalcenter_myorder.payment'), btn2: this.$t('m.personalcenter_myorder.Cancel_Order') }
        }
        if (orders === 31 || orders === 32 || orders === 33) {
          let dataArr = []
          for (const i of item.presellTimeScope.split(',')) {
            dataArr.push(i.slice(-10))
          }
          let timestatus = moment(new Date()).isBetween(dataArr[0], dataArr[1], 'days', '[]')
          dataArr = null
          if (timestatus || item.resaleFlag === 0) {
            timestatus = null
            return { btn2: '预提' }
          }
          return { btn2: '预提' }
          // return { btn1: '转售', btn2: '预提' }
        }
        if (orders === 34) {
          // return { btn1: '转售', btn2: '确认预提' }
          return { btn2: '确认预提' }
        }
        if (orders === 35) {
          return { btn2: '预提' }
        }
        if (orders === 3 || orders === 15 || orders === 17) {
          return { btn1: '确认备货' }
        }
        if (orders === 5) {
          return { btn1: '提醒发货' }
        }
        if (orders === 6) {
          if (item.orderType === 11) {
            return { btn2: '确认收货' }
          }
          return { btn1: '查物流', btn2: '确认收货' }
        }
        if (orders === 9) {
          return {}
        }
        if (orders === 8) {
          return { btn1: '确认发票' }
        }
        if (orders === 10) {
          return { btn1: '查物流' }
        }
        if (orders === 2) {
          return { btn1: '提醒备货' }
        }
        if (orders === 40) {
          return {}
        }
      }
      // console.log(msgBtn(orders))
      return msgBtn(orders)
    },
    orderBtn1 (item, index) { // 第一个上面 按钮
      // console.log(item) // 处理点击的不同事件
      if (item.orderType === 0 && item.status === 0) {
        this.payment(item)
      }
      if (item.orderType === 10 && item.status === 0) {
        this.payment(item)
      }
      switch (item.status) {
        case 8:
          this.ConfirmInvoice(item, index)
          break
        case 30:
          this.payment(item)
          break
        case 31:
          this.payment(item)
          break
        case 32:
          this.resellBought(item) // 转售
          break
        case 3:
          this.enterBeihuo(item, index) // 确认备货
          break
        default:
          break
      }
    },
    orderBtn2 (item, index) { // 第二个下面 按钮
      // console.log(item) // 处理点击的不同事件
      switch (item.status) {
        case 6:
          this.confirmReceipt(item, index) // 确认收货
          break
        case 32:
          this.withholding(item) // 预提
          break
        case 34:
          this.payment(item) // 确认预提
          break
        case 0:
          this.cancellationOrder(item, index) // 取消订单
          break
        case 12:
          this.cancellationOrder(item, index)
          break
        case 30:
          this.cancellationOrder(item, index)
          break
        case 31:
          this.cancellationOrder(item, index)
          break
        case 35:
          this.orderDetailPage(item)
          break
        default:
          break
      }
    },
    async cancellationOrder (item, index) { // 取消订单功能
      console.log(item)
      const data = {
        orderSn: item.orderSn
      }
      this.$confirm('您确定要取消此订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const res = cancelOrder(data)
          res.then(resj => {
            if (resj.data.code === 200) {
              ElMessage.success({
                message: resj.data.msg
              })
              this.ListData[index].status = 10
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch(() => {})
    },
    orderDetailPage (item) { // 去订单详情页面
      console.log(item)
      this.$router.push({
        path: '/personalcenter/myorder/detail',
        query: {
          orderSn: item.orderSn,
          orderType: item.orderType
        }
      })
    },
    confirmReceipt (item, index) { // 确认收货弹窗
      this.waitReceivingData = item
      this.orderType = item.orderType
      this.showDialog = 'waitReceiving'
      this.waitReceivingDialog = true
      this.dataIndex = index
      // console.log(this.ListData[index])
      // console.log(this.waitReceivingData)
    },
    enterBeihuo (item, index) { // 确认备货弹窗
      this.showDialog = 'enterBeihuo'
      // setStore('stockUpAccTime', moment(new Date()).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
      console.log(item)
      this.bhfinalAmount = item.finalAmount
      this.bhSn = item.orderSn
      this.bhData = [{
        orderSn: item.orderSn,
        stockUpAmount: item.stockUpAmount, // 备货数量
        freightAmount: item.pmsProduct.currencySymbol + (0 || item.freightAmount), // 物流费
        // jiagong: item.pmsProduct.currencySymbol + this.beihuoTestMoney, // 备货差价
        all: item.pmsProduct.currencySymbol + item.finalAmount // 应付金额/应退金额
      }]
      this.showBh = true
    },
    subConfirmGoods () { // 提交确认备货
      // console.log(this.bhData)
      this.$router.push(
        {
          path: '/buy/stockupaccounts',
          query: {
            orderSn: encodeURIComponent(this.bhSn.toString()),
            payAmount: encodeURIComponent(JSON.stringify(this.bhData[0].all))
          }
        }
      )
    },
    async confirmReceiptBtn () { // 确认收货
      console.log(this.waitReceivingData)
      this.loadingbtn = true
      const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
      // console.log(capitalAccount)
      // console.log(this.password)
      const md5password = this.$md5(`${capitalAccount}${this.password}`)
      const data = {
        orderSn: this.waitReceivingData.orderSn,
        password: md5password
      }
      if (/^\d{6}$/.test(this.password)) {
        // if (this.orderType === 11) { // 易货订单
        //   console.log('易货订单')
        //   if (this.orderType === 11) { // 易货摘牌人确认收货
        //     const res = await exchangeOrderConfirmDelivery(data)
        //     console.log(res)
        //     if (res.data.code === 200) {
        //     }
        //     this.loadingbtn = false
        //   }
        // } else {
        http.post('/mall-order/order/confirmDelivery', data)
          .then(res => {
            console.log(res.data)
            if (res.data.code === 200) {
              ElMessage.success({
                message: '确认收货成功'
              })
              this.loadingbtn = false
              this.password = ''
              this.waitReceivingDialog = false
              this.ListData[this.dataIndex].status = 8
            } else {
              this.loadingbtn = false
              this.password = ''
              ElMessage.warning({
                message: res.data.msg
              })
            }
          }).catch(err => {
            console.log(err)
            this.loadingbtn = false
            this.password = ''
          })
          // }
      } else {
        // loading.close()
        ElMessage.error({ message: '请输入6位支付密码' })
        this.loadingbtn = false
      }
      console.log(data)
    },
    ConfirmInvoice (item, index) { // 确认发票弹窗
      this.ConfirmInvoiceData = item
      this.showDialog = 'ConfirmInvoice'
      this.ConfirmInvoiceDialog = true
      this.dataIndex = index
    },
    ConfirmInvoiceBtn () { // 确认发票
      console.log(this.ConfirmInvoiceData)
      this.loadingbtn = true
      const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
      // console.log(capitalAccount)
      // console.log(this.password)
      const md5password = this.$md5(`${capitalAccount}${this.password}`)
      const data = {
        orderSn: this.ConfirmInvoiceData.orderSn,
        password: md5password
      }
      if (/^\d{6}$/.test(this.password)) {
        http.post('/mall-order/order/confirmInvioce', data)
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              ElMessage.success({
                message: '确认发票成功'
              })
              this.ListData[this.dataIndex].status = 9
            } else {
              ElMessage.error({
                message: res.data.msg
              })
            }
            this.loadingbtn = false
            this.password = ''
            this.ConfirmInvoiceDialog = false
          }).catch(err => {
            console.log(err)
            this.loadingbtn = false
          })
      } else {
        // loading.close()
        ElMessage.error({ message: '请输入6位支付密码' })
        this.loadingbtn = false
      }
      // console.log(data)
    },
    withholding (item) { // 点击预提-->申请预提
      this.$router.push({ // 到详情页面
        path: '/personalcenter/myorder/detail',
        query: {
          orderSn: item.orderSn
        }
      })
    },
    payment (item) { // 去付款
      console.log(item)
      if (item.status === 34) { // 确定预提支付
        setStore('WithAccTime', moment(item.createTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
        this.$router.push(
          {
            path: '/buy/withholdingaccount',
            query: {
              orderSn: encodeURIComponent(item.orderSn.toString()),
              payAmount: encodeURIComponent(JSON.stringify(
                item.pmsProduct.currencySymbol + item.finalAmount))
            }
          }
        )
      } else {
        setStore('settleAccTime', moment(item.createTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
        this.$router.push(
          {
            path: '/buy/settleaccounts',
            query: {
              orderSn: encodeURIComponent(item.orderSn.toString()),
              payAmount: encodeURIComponent(JSON.stringify(
                item.pmsProduct.currencySymbol + item.payAmount))
            }
          }
        )
      }
      // console.log(item)
    },
    resellBought (item) { // 转售弹窗
      this.resellBoughtData = item
      this.showDialog = 'resellBought'
      this.resellTime = this.resellBoughtData.presellTimeScope.split(',')[0]
      this.resellTime = this.resellTime.substring(this.resellTime.length - 10)
      this.resellBoughtDialog = true
      console.log(this.resellBoughtData)
    },
    disabledDate (time) { // 转售日期限制
      // 此条为设置禁止用户选择今天之前的日期，不包含今天。
      // return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000))
      return time.getTime() < (Date.now()) - (24 * 60 * 60 * 1000) ||
      time.getTime() > Date.parse(this.resellTime) - (24 * 60 * 60 * 1000)
    },
    resellBoughtBtn () { // 发布转售
      // console.log('我要转售')
      console.log(this.formData)
      this.$refs.formData.validate((sta) => {
        if (sta) {
          const postdata = {
            orderSn: this.resellBoughtData.orderSn,
            resalePrice: this.formData.price,
            quantity: this.formData.mount,
            endTime: this.formData.time,
            prePayPercent: this.resellBoughtData.pmsProduct.prePayRate
          }
          // console.log(postdata)
          http.post('/mall-order/resale/insert', postdata)
            .then(res => {
              console.log(res)
              if (res.data.code === 200) {
                ElMessage.success({
                  message: res.data.msg
                })
                this.resellBoughtData.omsOrderItem.productQuantity -= postdata.quantity
              } else {
                ElMessage.warning({
                  message: res.data.msg
                })
              }
              this.resellBoughtDialog = false
            }
            ).catch(err => {
              console.log(err)
            })
        } else {
          return null
        }
      })
    }
  },
  created () {
    emitter.on('orderDataTo', (data) => {
      this.ListData = data.list // 所有数据
      // this.ListData.omsOrderItem.
      this.listName = data.name // 当前选择的名
      this.totalnum = data.showNum // 当前页码
      // console.log(this.ListData)
    })
    // console.log(this.ListData)
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    const handleCurrentChange = (val) => {
      console.log(`current page: ${val}`)
      // this.listName
      // console.log(datacop.data.listName)
      emitter.emit('toOrderPageNum', { val: val, name: datacop.data.listName })
    }
    const handleChange = (value) => {
      // datacop.data.formData.mount = value
      console.log(value)// 目前输入的值
      const reselldata = datacop.data.resellBoughtData
      const minBuy = reselldata.pmsProduct.minBuy // 最小订货量
      const minIncr = reselldata.pmsProduct.minIncr // 最小增量
      const zengGou = value - minBuy // 增购的数量(选择的数量-最小订货量)
      if (zengGou >= minIncr) {
        console.log(Math.ceil(zengGou / minIncr))
        datacop.data.formData.mount = minBuy + Math.ceil(zengGou / minIncr) * minIncr
        // if (maxStock - this.thisBuyNum < minBuy) {
        //   this.thisBuyNum = maxStock
        // } else {
        //   // this.thisBuyNum = maxStock - minBuy
        // }
      } else {
        datacop.data.formData.mount = minIncr
        console.log(datacop.data.formData.mount)
      }

      // const okResell = (reselldata.omsOrderItem.productQuantity - reselldata.resaleNum) - value // 剩余数量
      // if (value === (reselldata.omsOrderItem.productQuantity - reselldata.resaleNum) - minIncr) {
      //   datacop.data.formData.mount = (reselldata.omsOrderItem.productQuantity - reselldata.resaleNum) - minBuy
      // } else {
      //   if (okResell < minBuy) {
      //   // console.log(okResell)
      //   // ElMessage.error({ message: `${minBuy}` })
      //   // datacop.data.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum
      //     if (okResell !== 0) {
      //       datacop.data.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum
      //     } else {
      //       datacop.data.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum
      //     }
      //   } else {
      //     if (okResell === minBuy) {
      //       datacop.data.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum - minBuy
      //     } else {
      //       if (parseInt(okResell / minBuy)) {
      //         // console.log(okResell)
      //         // console.log(okResell / minBuy)
      //         if (zengGou >= minIncr) {
      //           // console.log(Math.ceil(zengGou / minIncr))
      //           datacop.data.formData.mount = minBuy + Math.ceil(zengGou / minIncr) * minIncr
      //         } else {
      //           datacop.data.formData.mount = minIncr
      //         }
      //       } else {
      //         ElMessage.error({ message: `剩余不得低于最小订货量${minBuy}` })
      //         datacop.data.formData.mount = minBuy
      //       }
      //     }
      //   }
      // }
      value = datacop.data.formData.mount
      console.log(datacop.data.formData.mount)
    }
    const closeDialog = (val) => {
      val.mount = 0
      val.price = ''
      val.time = ''
    }
    return {
      handleSizeChange,
      handleChange,
      closeDialog,
      handleCurrentChange
    }
  }
}
</script>

<style lang="scss" >
.alltitle{
  width: 100%;
  background: #F7F7F7;
  border: 1px solid #EAEAEA;
  // margin-bottom: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  .all_title_ul{
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .all_title_li:first-child{
      width: 265px;
    }
    .all_title_li:nth-child(3){
      width: 200px;
    }
    .all_title_li:nth-child(5){
      width: 140px;
    }
    .all_title_li:not(:first-child):not(:nth-child(3)):not(:nth-child(5)){
      flex: 1;
    }
    .all_title_li{
      color: #999999;
    }
  }
}
.nodata{
  width: 100%;
  // margin: 0 auto;
  min-height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    margin: 0 auto;
    height: 300px;
  }
}
.order_div{
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  .order_title{
    background: #F7F7F7;
    border: 1px solid #EAEAEA;
    height: 50px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .order_title_p{
      // line-height: 50px;
      color: #999999;
    }
    .order_title_span{
      color: #999999;
      .order_title_button{
        margin-left: 20px;
        width: 100px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        color: #666666;
      }
    }
  }

  .order_body{
    height: 120px;
    // background: hotpink;
    .order_body_ul{
      display: flex;
      .order_body_li{
        // flex: 1;
        height: 120px;
        box-sizing: border-box;
        padding: 20px;
        flex-flow: row nowrap;
        border: 1px solid #EAEAEA;
        color: #999999;
        .money_span,p{
          color: #333333;
        }
      }
      .order_body_li:first-child{
        width: 265px;
        .namebody{
          display: flex;
          img{
            height: 80px;
            width: 80px;
          }
          .name_time{
            margin-left: 10px;
            p{
              width: 135px;
              line-height: 16px;
              text-overflow: ellipsis;
              white-space: nowrap;
              // overflow: hidden;
              color: #333333;
              margin-bottom: 15px;
            }
            span{
              line-height: 20px;
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
      .order_body_li:nth-child(2){
        .li2_span_kg{
          color: #333333;
        }
      }
      .order_body_li:nth-child(3){
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        .area_p{
          margin-top: 15px;
        }
      }
      .order_body_li:nth-child(5),.order_body_li:nth-child(6){
        width: 140px;
        display: flex;
        flex-flow: column;
        .status_name{
          color: #333333;
        }
        span,button{
          width: 100px;
          height: 30px;
        }
        button{
          background: #FFFFFF;
          border: 1px solid #C6824E;
          border-radius: 4px;
          color: #C6824E;
        }
        span,button:nth-child(1){
          margin-bottom: 10px;
        }
      }
      .order_body_li:not(:first-child):not(:nth-child(3)):not(:nth-child(5)):not(:nth-child(6)){
        flex: 1;
      }
    }
  }
  .order_foot{
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    padding: 0 20px;
    .order_foot_p{
      line-height: 40px;
      font-size: 14px;
      color: #C6824E;
    }
  }
}
.dialog_div{
  .el-overlay{
    .el-dialog{
      .el-dialog__body{
        .namebody{
          display: flex;
          flex-flow: column;
          align-items: center;
          text-align: center;
          h3{
            font-size: 16px;
            text-align: left;
            line-height: 30px;
          }
          img{
            width: 100px;
          }
          p{
            line-height: 40px;
          }
          .el-input{
            // margin: 20px;
            width: 30%;
          }
          .resell_div{
            text-align: left;
            // display: flex;
            flex-flow: column;
            border: 1px solid #999999;
            .el-form{
              .el-form-item{
                // margin: 0;
                .el-form-item__content{
                  .el-input{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .zs_dialogbody{
          display: flex;
          flex-flow: column;
          .resell_div{
            text-align: left;
            flex-flow: column;
            .pcont{
              padding:0 15px;
              line-height: 40px;
              color: #333333;
              span{
                color: #C6824E;
              }
            }
            .el-form{
              .el-form-item{
                // margin: 0;
                .el-form-item__content{
                  .el-input{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.showfooter{
    height: 100px;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
}
</style>
